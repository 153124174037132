import { useContext, useEffect, useState } from "react";
import ContentPrincipal from "../Content";
import { UserContext } from "../auth/Usercheck";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseUrl from "../BaseUrl";
import Chart from "react-apexcharts";
import separateNumber from "../separateur";
import moment from "moment";

const url = BaseUrl();
function Home(){
  const {user,setUsers}  = useContext(UserContext);
  const navigate = useNavigate();
  const [encaisse,setEncaisse] = useState(0);
  const [disponible,setDisponible] = useState(0);
  const [reverse,setReverse] = useState(0);
  const [todaysolde,setSolde] = useState(0);
  const [loading,setLoading] = useState();
  const [recentTrans,setRecentTrans] = useState([]);
  const [stateOperateurCompare,setStateOperateurCompare] = useState({ series: [], labels: [] });


  useEffect(()=>{
    fetchSoldeMarchands();
  },[user]);

  const fetchSoldeMarchands=async()=>{
    if(user)
    {
      setLoading(true);
      try {
        await  axios.post(url+'/solde/encaissement-marchand-transaction',{},
          {
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
            setLoading(false);
              if(resp.status == 200)
              {
                  setEncaisse(resp.data.data.encaisse);
                  setDisponible(resp.data.data.soldedispo);
                  setReverse(resp.data.data.reverse);
                  setSolde(resp.data.data.today);
                  setRecentTrans(resp.data.data.recentes);
                  setStateOperateurCompare(
                    {
                      labels: Object.values(resp.data.data.byoperateur),
                      series: Object.values(resp.data.data.byoperateur)
                    }
                  )
              }
          })
      } catch (error) {
          console.log(error);
      }
    }
  }


  const options1 = {
    colors: ['#ffbe0b','#14213d', '#606c38','#FF9800','#E91E63','#546E7A','#66DA26', '#D9534F',  '#80ed99','#e9ff70','#f5dfbb'],
    labels:  stateOperateurCompare.labels.map((label)=>label.operateur),
    legend: {
      show: true,
      position: 'bottom',
      },
}









    return (
        <>
            <ContentPrincipal navactive={"home"} ulShownav={""}>
            <div class="pagetitle">
            <h1>TABLEAU DE BORD</h1>
            {/* <nav>
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </nav> */}
            </div>

    <section class="section dashboard ">
      <div class="row bg-info pt-3 bg-opacity-25" >

      
        <div class="col-lg-8">
          <div class="row">

            
            <div class="col-xxl-6 col-md-12">
              <div class="card info-card sales-card">

                {/* <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div> */}

                <div class="card-body">
                  <h5 class="card-title">Montant brute encaissé </h5>

                  <div class="d-flex align-items-center">
                    {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-cart"></i>
                    </div> */}
                    <div class="ps-1">
                      {loading &&
                        <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      }
                      {!loading && <b>{separateNumber(parseFloat(encaisse).toFixed(0) ) } XOF</b>}
                      
                      
                      {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                    </div>
                  </div>
                </div>

              </div>
            </div>

         
            <div class="col-xxl-6 col-md-12">
              <div class="card info-card revenue-card">


                <div class="card-body">
                  <h5 class="card-title">Solde disponible</h5>

                  <div class="d-flex align-items-center">
                    {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-currency-dollar"></i>
                    </div> */}
                    <div class="ps-1">
                      {loading &&
                        <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      }
                      {!loading && <b>{separateNumber(parseFloat(disponible).toFixed(0))} XOF</b>}
                      {/* <span class="text-success small pt-1 fw-bold">8%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                    </div>
                  </div>
                </div>

              </div>
            </div>

         
            <div class="col-xxl-6 col-xl-12">

              <div class="card info-card customers-card">

                {/* <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div> */}

                <div class="card-body">
                  <h5 class="card-title">Demande de reversement</h5>

                  <div class="d-flex align-items-center">
                    {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-people"></i>
                    </div> */}
                    <div class="ps-1">
                    {loading &&
                        <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      }
                      {!loading && <b>{reverse} XOF</b>}
                      {/* <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span> */}

                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class="col-xxl-6 col-xl-12">
              <div class="card">
                <div class="info-card sales-card">

              

                  <div class="card-body">
                    <h5 class="card-title">Encaissement <span>| Jour</span></h5>

                    <div class="d-flex align-items-center">
                      {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i class="bi bi-cart"></i>
                      </div> */}
                      <div class="ps-3">
                      {loading &&
                        <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      }
                      {!loading && <b>{separateNumber(todaysolde)} XOF</b>}
                        {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                      </div>
                    </div>
                  </div>

                </div>
              </div>
              </div>

          
          

                <div class="col-12">
                <div class="card recent-sales overflow-auto">
  
          
                  <div class="card-body">
                    <h5 class="card-title">Encaissement <span>| Recents</span></h5>
  
                    <table class="table table-borderless datatable">
                      <thead>
                        <tr>
                          <th scope="col">DATE</th>
                          <th scope="col">ID-TRANSACTION</th>
                          <th scope="col">OPERATEUR</th>
                          <th scope="col">NUMERO</th>
                          <th scope="col">MONTANT</th>
                          <th scope="col">STATUT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && 
                            <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        }
                        {!loading &&
                          recentTrans.map((trans,index)=>
                            <tr> 
                              <th>{moment(trans.date_trans).format('Do MMMM YYYY H:m')}</th>
                              <th scope="row">{trans.transaction_id}</th>
                              <td>{trans.operateur}</td>
                              <td>{trans.tel_payment}</td>
                              <td>{trans.montant_initial} XOF</td>
                              <td>
                                {trans.status_payment == "ACCEPTED" && <span class="badge bg-success">Succès</span>}
                                {trans.status_payment == "REFUSED" && <span class="badge bg-danger">Echèc</span>}
                                {trans.status_payment == "EN ATTENTE" && <span class="badge bg-warning">En attente</span>}
                                
                              </td>
                          </tr>
                          )
                        }
                      
                      </tbody>
                    </table>
  
                  </div>
  
                </div>
              </div>
           


            
         

          </div>
        </div>


                  <div class="col-lg-4">
                  <div class="card">
                   
                    <div class="card-body pb-0">
                      <h5 class="card-title">Opérateur <span>| Semaine</span></h5>
                      {loading &&
                                <div class="spinner-grow" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              }
                     {!loading && 
                        <Chart
                            options={options1}
                            series={stateOperateurCompare.series.map((serie)=>parseInt(serie.total))}
                            type="donut" 
                            width="100%"
                            
                        />
                        }
        
                      
        
                    </div>
                  </div>
        
                </div> 
        



      </div>
    </section>
            </ContentPrincipal>
        </>
    )
}

export default Home;