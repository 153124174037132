import { Link, useNavigate } from "react-router-dom";
import UserAuth, { UserContext } from "./auth/Usercheck";
import { useContext, useEffect } from "react";
import axios from "axios";
import BaseUrl from "./BaseUrl";
import can from "./auth/CanUser";


const url = BaseUrl();
function Sidebar({navactive,ulShownav}){
  const {user,setUsers} = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(()=>{

  },[user]);

  const logout = ()=>{
    try {
        axios.get(url+'/logout',
    {
        headers:{
            'Content-Type':'application/json',
            "Authorization": `Bearer ${localStorage.getItem('_token_')}`
            
        },
        // credentials:'include'
    }
    ).then((resp)=>{
          if(resp.status == 200){
              if(resp.data.error){
                  localStorage.removeItem('_token_');
                  navigate('/');
              }
              localStorage.removeItem('_token_');

              navigate('/');
          }else{
              localStorage.removeItem('_token_');
              navigate('/');
          }
      });
    } catch (error) {
        console.log(error.response.status);
       
    }
    
}

  // console.log(user?.rUser);
  return (
      <>
          <aside id="sidebar" class="sidebar ">

              <ul class="sidebar-nav" id="sidebar-nav">

                  {can(user,'tableau-de-bord-marchand') &&
                    <li class="nav-item">
                    <Link className={navactive && navactive =='home' ? "nav-link  active" : "nav-link collapsed"} to="/dashboard">
                      <i class="bi bi-grid"></i>
                      <span>TABLEAU DE BORD</span>
                    </Link>
                  </li>
                  }
                  {can(user,'soft-tab') &&
                    <li class="nav-item">
                      <Link className={navactive && navactive =='soft' ? "nav-link  active" : "nav-link collapsed"} to="/dash-softexpertise">
                        <i class="bi bi-grid"></i>
                        <span>TAB SOFTEXPERTISE</span>
                      </Link>
                    </li>
                  }
              
                  {/* <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                      <i class="bi bi-menu-button-wide"></i><span>Components</span><i class="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li>
                        <a href="components-alerts.html">
                          <i class="bi bi-circle"></i><span>Alerts</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-accordion.html">
                          <i class="bi bi-circle"></i><span>Accordion</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-badges.html">
                          <i class="bi bi-circle"></i><span>Badges</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-breadcrumbs.html">
                          <i class="bi bi-circle"></i><span>Breadcrumbs</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-buttons.html">
                          <i class="bi bi-circle"></i><span>Buttons</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-cards.html">
                          <i class="bi bi-circle"></i><span>Cards</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-carousel.html">
                          <i class="bi bi-circle"></i><span>Carousel</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-list-group.html">
                          <i class="bi bi-circle"></i><span>List group</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-modal.html">
                          <i class="bi bi-circle"></i><span>Modal</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-tabs.html">
                          <i class="bi bi-circle"></i><span>Tabs</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-pagination.html">
                          <i class="bi bi-circle"></i><span>Pagination</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-progress.html">
                          <i class="bi bi-circle"></i><span>Progress</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-spinners.html">
                          <i class="bi bi-circle"></i><span>Spinners</span>
                        </a>
                      </li>
                      <li>
                        <a href="components-tooltips.html">
                          <i class="bi bi-circle"></i><span>Tooltips</span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
                      <i class="bi bi-journal-text"></i><span>Forms</span><i class="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="forms-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li>
                        <a href="forms-elements.html">
                          <i class="bi bi-circle"></i><span>Form Elements</span>
                        </a>
                      </li>
                      <li>
                        <a href="forms-layouts.html">
                          <i class="bi bi-circle"></i><span>Form Layouts</span>
                        </a>
                      </li>
                      <li>
                        <a href="forms-editors.html">
                          <i class="bi bi-circle"></i><span>Form Editors</span>
                        </a>
                      </li>
                      <li>
                        <a href="forms-validation.html">
                          <i class="bi bi-circle"></i><span>Form Validation</span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#">
                      <i class="bi bi-layout-text-window-reverse"></i><span>Tables</span><i class="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="tables-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                      <li>
                        <a href="tables-general.html">
                          <i class="bi bi-circle"></i><span>General Tables</span>
                        </a>
                      </li>
                      <li>
                        <a href="tables-data.html">
                          <i class="bi bi-circle"></i><span>Data Tables</span>
                        </a>
                      </li>
                    </ul>
                  </li> */}

                  <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#charts-nav" data-bs-toggle="collapse" href="#">
                      <i class="bi bi-bar-chart"></i><span>TRANSACTIONS</span><i class="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="charts-nav" className={ulShownav && ulShownav == 'transaction' ? "nav-content collapse show" : "nav-content collapse "} data-bs-parent="#sidebar-nav">
                    
                      {can(user,'voir-tous-paiements') && 
                      <li>
                          <Link to="/paiements-recoltes-all" className={navactive && navactive =='collect-all' ? "active" : ""}>
                            <i class="bi bi-circle"></i><span>Tous les paiements</span>
                          </Link>
                        </li>    }

                        {can(user,'voir-paiements-marchand') && 
                        <li>
                        <Link to="/paiements-recoltes" className={navactive && navactive =='collect' ? "active" : ""}>
                          <i class="bi bi-circle"></i><span>Paiements Collectés</span>
                        </Link>
                      </li>}
                      {/* <li>
                        <a href="charts-apexcharts.html">
                          <i class="bi bi-circle"></i><span>Exports</span>
                        </a>
                      </li>className={navactive && navactive =='reversement' ? "active" : ""} */}
                    
                    </ul>
                  </li>

                {/* <li class="nav-item">
                  <a class="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-gem"></i><span>Icons</span><i class="bi bi-chevron-down ms-auto"></i>
                  </a>
                  <ul id="icons-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                      <a href="icons-bootstrap.html">
                        <i class="bi bi-circle"></i><span>Bootstrap Icons</span>
                      </a>
                    </li>
                    <li>
                      <a href="icons-remix.html">
                        <i class="bi bi-circle"></i><span>Remix Icons</span>
                      </a>
                    </li>
                    <li>
                      <a href="icons-boxicons.html">
                        <i class="bi bi-circle"></i><span>Boxicons</span>
                      </a>
                    </li>
                  </ul>
                </li>  */}

                <li class="nav-heading">Pages</li> 
                {can(user,'voir-page-reversement') && 
                <li class="nav-item">
                  <Link className={navactive && navactive =='reversement' ? "nav-link active" : "nav-link collapsed"} to="/reversement-marchand" >
                    <i class="bi bi-file-earmark"></i>
                    <span>Reversement</span>
                  </Link>
                </li>}

                {can(user,'voir-page-marchand') &&
                  <li class="nav-item">
                    <Link  className={navactive && navactive =='marchands' ? "nav-link  active" : "nav-link collapsed"} to="/utilisateur-marchands" >
                      <i class="bi bi-people"></i>
                      <span>Marchands</span>
                    </Link>
                </li>}


                
                {/* <li class="nav-item">
                  <a class="nav-link collapsed" href="pages-contact.html">
                    <i class="bi bi-envelope"></i>
                    <span>Contact</span>
                  </a>
                </li> */}
                <li class="nav-item">
                  <a class="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-gem"></i><span>Parametrages</span><i class="bi bi-chevron-down ms-auto"></i>
                  </a>
                  <ul id="icons-nav" className={ulShownav && ulShownav == 'parametrage' ? "nav-content collapse show" : "nav-content collapse "} data-bs-parent="#sidebar-nav">
                  {/* {can(user,'voir-page-control-acces') &&
                    <li class="nav-item">
                      <Link to="/privileges-acces" className={navactive && navactive =='controlaccess' ? "nav-link  active" : "nav-link collapsed"}>
                      <i class="bi-solid bi-lock"></i>
                        <span>Controle d'accès</span>
                      </Link>
                    </li>} */}

                    {can(user,'voir-page-control-acces') &&
                    <li class="nav-item">
                      <Link to="/privileges-roles" className={navactive && navactive =='controlroles' ? "nav-link  active" : "nav-link collapsed"}>
                      <i class="bi-solid bi-lock"></i>
                        <span>Rôles controles</span>
                      </Link>
                    </li>}

                    {can(user,'voir-page-permission') &&
                      <li class="nav-item">
                        <Link class={navactive && navactive =='roles' ? "nav-link  active" : "nav-link collapsed"} to="/roles-list">
                          <i class="bi bi-card-list"></i>
                          <span>Rôles</span>
                        </Link>
                      </li>}
                    {can(user,'voir-page-permission') &&
                      <li class="nav-item">
                        <Link class={navactive && navactive =='permissions' ? "nav-link  active" : "nav-link collapsed"} to="/users-permissions">
                          <i class="bi bi-card-list"></i>
                          <span>Permissions</span>
                        </Link>
                      </li>}

                      {can(user,'voir-page-profil') &&
                        <li class="nav-item">
                          <Link className={navactive && navactive =='profil' ? "nav-link  active" : "nav-link collapsed"} to="/profil-marchand" >
                            <i class="bi bi-person"></i>
                            <span>Mon Compte</span>
                          </Link>
                        </li>
                        }
                    {/* <li>
                      <a href="icons-boxicons.html">
                        <i class="bi bi-circle"></i><span>Boxicons</span>
                      </a>
                    </li> */}
                  </ul>
                </li> 

                {can(user,'voir-page-utilisateur') &&
                <li class="nav-item">
                  <Link className={navactive && navactive =='users' ? "nav-link  active" : "nav-link collapsed"} to="/utilisateurs" >
                    <i class="bi bi-person"></i>
                    <span>Utilisateurs</span>
                  </Link>
                </li>}
                
                {can(user,'agents-page') &&
                <li class="nav-item">
                  <Link className={navactive && navactive =='people-users' ? "nav-link  active" : "nav-link collapsed"} to="/people-users" >
                    <i class="ri-group-2-fill"></i>
                    <span>Mes agents</span>
                  </Link>
                </li>}


                <li class="nav-item">
                  <button class="nav-link collapsed btn" onClick={logout}>
                    <i class="bi bi-box-arrow-in-right"></i>
                    <span>Se Deconnecter</span>
                  </button>
                </li>

                {/* <li class="nav-item">
                  <a class="nav-link collapsed" href="pages-error-404.html">
                    <i class="bi bi-dash-circle"></i>
                    <span>Error 404</span>
                  </a>
                </li>  */}



              </ul>

          </aside>
      </>
  );
}

export default Sidebar;