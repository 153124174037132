import { useContext, useEffect, useState } from "react";
import ContentPrincipal from "../Content";
import UserAuth, { UserContext } from "../auth/Usercheck";
import axios from "axios";
import BaseUrl from "../BaseUrl";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ExportExcel from "../ExcelExport";
import TransactionExportPdf from "./pdf/TransactonPdf";
import can from "../auth/CanUser";
import separateNumber from "../separateur";

const url = BaseUrl();
function PaiementRecolte()
{

    const navigate = useNavigate();


    const {user, setUsers} = useContext(UserContext);
    const [tranDatas,setTransDatas] = useState([]);
    const [loadingData,setLoadingData] = useState();
    const [statusTransData,setStatusTransData] = useState('');
    const [opTransData,setOpTransData] = useState('');
    const [startTransDate,SetStartTransDate] = useState();
    const [endTransDate,SetEndTransDate] = useState();
    const [transaction,setTransaction] = useState([]);

    const [marchands,setMarchands] = useState([]);
    const [marchandSiteID,setMarchandSiteID]= useState('');

    const [currentPage,setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records =tranDatas?.length > 0 ? tranDatas.slice(firstIndex, lastIndex): [];
    const npage =tranDatas?.length > 0 ? Math.ceil(tranDatas.length / recordsPerPage) : 0;
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [excelData,setExcelData] = useState({});
 


    useEffect(()=>{
        if(!can(user,'voir-paiements-marchand')){
            navigate('/');
        }
        fetchDataTrans();
        fetchDataMarchands();
    },[user]);

    const fetchDataTrans =async()=> {
        setLoadingData(false);
        const transArray = [];
        if(user){
            try {
              await  axios.post(url+'/get/marchand-transactions',{},
                {
                    headers:{
                        'Content-Type':'application/json',
                        "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                        
                    },
                    // credentials:'include'
                }
                ).then((resp)=>{
                    setLoadingData(true);
                    if(resp.status == 200)
                    {
                        setTransDatas(resp.data.data);

                        resp.data.data.forEach(trans => { 
                            transArray.push({
                                "Date":moment(trans.cpm_trans_date).format("Do MMMM YYYY H:mm:ss"),
                                "ID-Transaction":trans.transaction_id,
                                "SITE ID":trans.siteID_distripay,
                                "COMMERCIAL":trans.distri_seller_name,
                                "NUMERO":trans.tel_payment,
                                "OPERATEUR":trans.operateur,
                                "MONTANT":trans.montant_initial,
                                "STATUT":trans.status_payment
                            });
                        })
                        setExcelData(transArray);
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }
    }


    // console.log(excelData);

    const fetchDataMarchands=async()=>{
        // setLoading(true);
        try {
            axios.get(url+'/all/marchands',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                // setLoading(false);
                if(resp.status == 200)
                {
                    setMarchands(resp.data.datas);
                }
            })
        } catch (error) {
            
        }
    }

    const fetchStatusTrans = async (e) => {
        setLoadingData(false);
        var status = e.target.value;
        setStatusTransData(status);
        if(status !== "")
        {

            if(opTransData !== "")
            {
        
                try {
                    await  axios.post(url+'/get/marchand-transactions',{operateur:opTransData,status:status},
                      {
                          headers:{
                              'Content-Type':'application/json',
                              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                              
                          },
                          // credentials:'include'
                      }
                      ).then((resp)=>{
                          setLoadingData(true);
                          if(resp.status == 200)
                          {
                              setTransDatas(resp.data.data);
                          }
                      })
                  } catch (error) {
                      console.log(error);
                  }
           
            }else{
            
                    try {
                        await  axios.post(url+'/get/marchand-transactions',{status:status},
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                            // credentials:'include'
                        }
                        ).then((resp)=>{
                            setLoadingData(true);
                            if(resp.status == 200)
                            {
                                setTransDatas(resp.data.data);
                            }
                        })
                    } catch (error) {
                        console.log(error);
                    }
            
            }
            
        }else{
            fetchDataTrans();
        }
    }


    const fetchOperateurTrans = async (e) => {
        var operateur = e.target.value;
        setOpTransData(operateur);
        setLoadingData(false);
        if(operateur !=="")
        {
            if(statusTransData !== "")
            {
          
                    try {
                        await  axios.post(url+'/get/marchand-transactions',{operateur:operateur,status:statusTransData},
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                            // credentials:'include'
                        }
                        ).then((resp)=>{
                            setLoadingData(true);
                            if(resp.status == 200)
                            {
                                setTransDatas(resp.data.data);
                            }
                        })
                    } catch (error) {
                        console.log(error);
                    }
            
            }else{
            
                    try {
                        await  axios.post(url+'/get/marchand-transactions',{operateur:operateur},
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                            // credentials:'include'
                        }
                        ).then((resp)=>{
                            setLoadingData(true);
                            if(resp.status == 200)
                            {
                                setTransDatas(resp.data.data);
                            }
                        })
                    } catch (error) {
                        console.log(error);
                    }
                
            }
            
        }else{
            fetchDataTrans();
        }


    }


    const fetchDataByDateTrans=async(e)=>{
        var start = startTransDate;
        var end = e.target.value;

        setLoadingData(false);
        if(start !== "" && end !==""){
            if(opTransData !== "")
            {
           
                try {
                    await  axios.post(url+'/get/marchand-transactions',{operateur:opTransData,start_date:start,end_date:end},
                      {
                          headers:{
                              'Content-Type':'application/json',
                              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                              
                          },
                          // credentials:'include'
                      }
                      ).then((resp)=>{
                          setLoadingData(true);
                          if(resp.status == 200)
                          {
                              setTransDatas(resp.data.data);
                          }
                      })
                  } catch (error) {
                      console.log(error);
                  }
            
        }else{
            
                try {
                    await  axios.post(url+'/get/marchand-transactions',{start_date:start,end_date:end},
                      {
                          headers:{
                              'Content-Type':'application/json',
                              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                              
                          },
                          // credentials:'include'
                      }
                      ).then((resp)=>{
                          setLoadingData(true);
                          if(resp.status == 200)
                          {
                              setTransDatas(resp.data.data);
                          }
                      })
                  } catch (error) {
                      console.log(error);
                  }
          
        }
            
        }else{
            fetchDataTrans();
        }
        // console.log(start,end);
    }

    const fetchTransMarch = async (e) => {
        setLoadingData(false);
        var marchand = e.target.value;
        setMarchandSiteID(marchand);
        // setStatusTransData(status);
        if(marchand !== "")
        {

                try {
                    await  axios.post(url+'/get/marchand-transactions',{marchand_id:marchand},
                      {
                          headers:{
                              'Content-Type':'application/json',
                              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                              
                          },
                          // credentials:'include'
                      }
                      ).then((resp)=>{
                          setLoadingData(true);
                          if(resp.status == 200)
                          {
                              setTransDatas(resp.data.data);
                          }
                      })
                  } catch (error) {
                      console.log(error);
                  }
           
        
            
        }else{
            fetchDataTrans();
        }
    }

    const fetchOperateurTransMarch = async (e) => {
        var operateur = e.target.value;
        setOpTransData(operateur);
        setLoadingData(false);
        if(operateur !=="")
        {
            if(marchandSiteID !== "")
            {
          
                    try {
                        await  axios.post(url+'/get/marchand-transactions',{operateur:operateur,marchand_id:marchandSiteID},
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                            // credentials:'include'
                        }
                        ).then((resp)=>{
                            setLoadingData(true);
                            if(resp.status == 200)
                            {
                                setTransDatas(resp.data.data);
                            }
                        })
                    } catch (error) {
                        console.log(error);
                    }
            
            }else{
            
                    try {
                        await  axios.post(url+'/get/marchand-transactions',{operateur:operateur},
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                            // credentials:'include'
                        }
                        ).then((resp)=>{
                            setLoadingData(true);
                            if(resp.status == 200)
                            {
                                setTransDatas(resp.data.data);
                            }
                        })
                    } catch (error) {
                        console.log(error);
                    }
                
            }
            
        }else{
            fetchDataTrans();
        }


    }

    const fetchDataByDateTransMarch=async(e)=>{
        var start = startTransDate;
        var end = e.target.value;

        setLoadingData(false);
        if(start !== "" && end !==""){
            if(marchandSiteID !== "")
            {
           
                try {
                    await  axios.post(url+'/get/marchand-transactions',{marchand_id:marchandSiteID,start_date:start,end_date:end},
                      {
                          headers:{
                              'Content-Type':'application/json',
                              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                              
                          },
                          // credentials:'include'
                      }
                      ).then((resp)=>{
                          setLoadingData(true);
                          if(resp.status == 200)
                          {
                              setTransDatas(resp.data.data);
                          }
                      })
                  } catch (error) {
                      console.log(error);
                  }
            
        }else{
            
                try {
                    await  axios.post(url+'/get/marchand-transactions',{start_date:start,end_date:end},
                      {
                          headers:{
                              'Content-Type':'application/json',
                              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                              
                          },
                          // credentials:'include'
                      }
                      ).then((resp)=>{
                          setLoadingData(true);
                          if(resp.status == 200)
                          {
                              setTransDatas(resp.data.data);
                          }
                      })
                  } catch (error) {
                      console.log(error);
                  }
          
        }
            
        }else{
            fetchDataTrans();
        }
        // console.log(start,end);
    }


    const searchDataTrans=async(e)=>{
        var search = e.target.value;
   
            try {
                await  axios.post(url+'/get/search-transactions',{search:search},
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                      // credentials:'include'
                  }
                  ).then((resp)=>{
                    //   setLoadingData(true);
                      if(resp.status == 200)
                      {
                          setTransDatas(resp.data.data);
                      }
                  })
              } catch (error) {
                  console.log(error);
              }
       
    }


    function prePage()
    {
        if(currentPage !== 1)
        {
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage()
    {
        if(currentPage !== npage)
        {
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(n)
    {
       setCurrentPage(n);
    }


    const openModalViewTrans =(transaction)=>{
        setTransaction(transaction);
        window.$('#basicModal').modal('show');
    }
    


// console.log(statusTransData);

    return (
        <>

            <ContentPrincipal navactive={"collect"} ulShownav={"transaction"}>


                    <div class="pagetitle mb-3">
                    <h1>Paiements Collectés 
                        <ExportExcel excelData={excelData} filename={'Export Transaction Xls'}/>
                        <TransactionExportPdf pdfData={tranDatas} />
                    </h1>
                    
                    </div>

                    <section class="section">
                      
                             <div class="row">
                             <div class="col-lg-12">
 
                             <div class="card">
                                 <div class="card-body">
                                 <h5 class="card-title">Liste des paiements collectés</h5>
                              
                                        <div class="card-header row g-4">
                                        <div class="col-md-6">
                                            <div class="d-flex">
                                           
                                                <select  id="" class="form-select mb-3 spinner-filter" onChange={fetchStatusTrans}>
                                                    <option value="">Statut</option>
                                                    <option value="ACCEPTED">Succès</option>
                                                    <option value="EN ATTENTE">En attente</option>
                                                    <option value="REFUSED">Echec</option>
                                                </select>
                                                <select id="" class="form-select mb-3 mx-1 spinner-filter" onChange={fetchOperateurTrans}>
                                                    <option value="">Opérateur</option>
                                                    <option value="MOOV">MOOV</option>
                                                    <option value="OM">OM</option>
                                                    <option value="MOMO">MOMO</option>
                                                    <option value="WAVE">WAVECI</option>
                                            </select>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="d-flex">
                                                <input  id="periodRange" type="date" name="startTransDate" class="h-100 p-2 mx-2 period-select form-control" onChange={(e)=>SetStartTransDate(e.target.value)}/>
                                                <input  id="periodRange" type="date" name="endTransDate" class="h-100 p-2 mx-2 period-select form-control" onChange={fetchDataByDateTrans}/>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="d-flex">
                                                <input type="search" placeholder="Rechercher..." class="form-control" name="search" id="search" onChange={searchDataTrans}/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                 <table class="table datatable">
                                     <thead>
                                     <tr>
                                         <th>DATE</th>
                                         <th>ID-TRANSACTION</th>
                                         {/* <th>COMMERCIAL</th> */}
                                         <th>NUMERO</th>
                                         <th>OPERATEUR</th>
                                         <th >MONTANT(XOF)</th>
                                         <th>STATUT</th>
                                         <th>ACTION</th>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     {/* {moment(compteRendu.created_at).format("dddd,Do MMMM  YYYY")} */}
                                         {!loadingData && 
                                            <tr className="text-center">                                             
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>                                         
                                            </tr>
                                         }
                                         {loadingData && 
                                             records.map((trans,index)=>
                                             <tr>
                                                 <td>{moment(trans.cpm_trans_date !=null? trans.cpm_trans_date:trans.date_trans).format("Do MMMM YYYY H:mm:ss")}</td>
                                                 <td>{trans.transaction_id}</td>
                                                 {/* <td>{trans.distri_seller_name}</td> */}
                                                 <td>{trans.tel_payment}</td>
                                                 <td>{trans.operateur}</td>
                                                 <td className="text-center">{separateNumber(trans.montant_initial) } </td>
                                                 <td>
                                                     {trans.status_payment == "ACCEPTED" && <span class="badge bg-success">Succès</span>}
                                                     {trans.status_payment == "EN ATTENTE" && <span class="badge bg-warning">En attente</span>}
                                                     {trans.status_payment == "REFUSED" && <span class="badge bg-danger">Echèc</span>}
                                                 </td>
                                                 <td>
                                                     <button className="btn btn-default" onClick={()=>openModalViewTrans(trans)}><i class="ri-eye-line"></i></button>
                                                 </td>
                                             </tr>
                                             )
                                         }
                                     
                                     </tbody>
                                 </table>
 
                                 <nav aria-label="Page navigation example" className="float-end">
                                     <ul class="pagination">
                                     <li class="page-item">
                                         <a class="page-link"   onClick={prePage}>
                                         <span aria-hidden="true">«</span>
                                         </a>
                                     </li>
                                     {numbers.map((n,i)=>
                                         <li class={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                             <a class="page-link"  onClick={()=>changePage(n)}>{n}</a>
                                         </li>
                                     )}
                                     
 
                                     <li class="page-item">
                                         <a class="page-link" onClick={nextPage}>
                                         <span aria-hidden="true">»</span>
                                         </a>
                                     </li>
                                     </ul>
                                 </nav>
 
 
                                 </div>
                             </div>
 
                             </div>
                            </div>
                       
                       

                    </section>


                    <div class="modal fade" id="basicModal" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-x">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Information sur la transaction</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                            <table class="table table-striped">
                             
                                <tbody>
                                <tr>
                                    <td><b>ID TRANSACTION</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.transaction_id}</td>
                                </tr>
                                <tr>
                                    <td><b>REFERENCE</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.codeIzypay}</td>
                                </tr>
                                <tr>
                                    <td><b>Date Création (GMT)</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{moment(transaction.cpm_trans_date !=null? transaction.cpm_trans_date:transaction.date_trans).format("Do MMMM YYYY H:mm:ss")}</td>
                                </tr>
                                <tr>
                                    <td><b>Opérateur</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.operateur}</td>
                                </tr>
                                <tr>
                                    <td><b>ID Opérateur</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.operateur_id}</td>
                                </tr>

                                <tr>
                                    <td><b>Téléphone</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.tel_payment}</td>
                                </tr>

                                <tr>
                                    <td><b>Montant payé</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.montant_initial}</td>
                                </tr>

                                <tr>
                                    <td><b>Devise</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.devise}</td>
                                </tr>

                                <tr>
                                    <td><b>Statut</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.status_payment}</td>
                                </tr>

                                <tr>
                                    <td><b>Message</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.message_trans}</td>
                                </tr>

                                <tr>
                                    <td><b>Erreur Message</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.cpm_error_message}</td>
                                </tr>

                                <tr>
                                    <td><b>Description</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transaction.commentaire_trans}</td>
                                </tr>

                                </tbody>
                            </table>
                            </div>
                           
                        </div>
                        </div>
                    </div>
            </ContentPrincipal>
        </>
    )
}

export default PaiementRecolte;