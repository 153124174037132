import { useContext, useEffect, useState } from "react";
import ContentPrincipal from "../Content";
import { UserContext } from "../auth/Usercheck";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseUrl from "../BaseUrl";
import Chart from "react-apexcharts";
import separateNumber from "../separateur";
import moment from "moment";

const url = BaseUrl();
function HomeSoft(){
  const {user,setUsers}  = useContext(UserContext);
  const navigate = useNavigate();
  const [encaisse,setEncaisse] = useState(0);
  const [disponible,setDisponible] = useState(0);
  const [reverse,setReverse] = useState(0);
  const [todaysolde,setSolde] = useState(0);
  const [loading,setLoading] = useState();
  const [recentTrans,setRecentTrans] = useState([]);
  const [stateOperateurCompare,setStateOperateurCompare] = useState({ series: [], labels: [] });


  useEffect(()=>{
    fetchSoldeMarchands();
  },[user]);

  const fetchSoldeMarchands=async()=>{
    if(user)
    {
      setLoading(true);
      try {
        await  axios.get(url+'/stat_tab_marchand',
          {
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
            setLoading(false);
              if(resp.status == 200)
              {
                  setEncaisse(resp.data.total_payment_accepted);
                  setDisponible(resp.data.total_gain_softexpertise);
                  setReverse(resp.data.total_payment_refused);
                  // setSolde(resp.data.data.today);
                  setRecentTrans(resp.data.transaction_marchands);
                  // setStateOperateurCompare(
                  //   {
                  //     labels: Object.values(resp.data.data.byoperateur),
                  //     series: Object.values(resp.data.data.byoperateur)
                  //   }
                  // )
              }
          })
      } catch (error) {
          console.log(error);
      }
    }
  }


  const options1 = {
    colors: ['#ffbe0b','#14213d', '#606c38','#FF9800','#E91E63','#546E7A','#66DA26', '#D9534F',  '#80ed99','#e9ff70','#f5dfbb'],
    labels:  stateOperateurCompare.labels.map((label)=>label.operateur),
    legend: {
      show: true,
      position: 'bottom',
      },
}









    return (
        <>
            <ContentPrincipal navactive={"soft"} ulShownav={""}>
            <div class="pagetitle">
            <h1>SOFTEXPERTISE</h1>
            {/* <nav>
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </nav> */}
            </div>

    <section class="section dashboard ">
      <div class="row bg-warning pt-3 bg-opacity-25" >

      
        <div class="col-lg-12">
          <div class="row">

            
            <div class="col-xxl-4 col-md-12">
              <div class="card info-card sales-card">

                {/* <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                    <li><a class="dropdown-item" href="#">This Month</a></li>
                    <li><a class="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div> */}

                <div class="card-body">
                  <h5 class="card-title">Montant brute </h5>

                  <div class="d-flex align-items-center">
                    {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-cart"></i>
                    </div> */}
                    <div class="ps-1">
                      {loading &&
                        <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      }
                      {!loading && <b className="text-success">{separateNumber(parseInt(encaisse) ) } XOF</b>}
                      
                      
                      {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                    </div>
                  </div>
                </div>

              </div>
            </div>

         
            <div class="col-xxl-4 col-md-12">
              <div class="card info-card revenue-card">


                <div class="card-body">
                  <h5 class="card-title">Pourcentage SOFTEXPERTISE</h5>

                  <div class="d-flex align-items-center">
                    {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-currency-dollar"></i>
                    </div> */}
                    <div class="ps-1">
                      {loading &&
                        <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      }
                      {!loading && <b className="text-success">{separateNumber(parseFloat(disponible).toFixed(0))} XOF</b>}
                      {/* <span class="text-success small pt-1 fw-bold">8%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-xxl-4 col-md-12">
              <div class="card info-card revenue-card">


                <div class="card-body">
                  <h5 class="card-title">Montant brute échoué</h5>

                  <div class="d-flex align-items-center">
                    {/* <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-currency-dollar"></i>
                    </div> */}
                    <div class="ps-1">
                      {loading &&
                        <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      }
                      {!loading && <b className="text-danger">{separateNumber(parseFloat(reverse).toFixed(0))} XOF</b>}
                      {/* <span class="text-success small pt-1 fw-bold">8%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                    </div>
                  </div>
                </div>

              </div>
            </div>

         
         


          
          

                <div class="col-12">
                <div class="card recent-sales overflow-auto">
  
          
                  <div class="card-body">
                    <h5 class="card-title">Liste des marchands</h5>
  
                    <table class="table table-borderless datatable">
                      <thead>
                        <tr>
                          <th scope="col">MARCHAND</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col" className="text-center">POURCENTAGE(%)</th>
                          <th scope="col" className="text-center">MT BRUTE (FCFA)</th>
                          <th scope="col" className="text-center">MT DISPONIBLE (FCFA)</th>
                          <th scope="col" className="text-center">MT SOFTEXPERTISE (FCFA)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && 
                            <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        }
                        {!loading &&
                          recentTrans.map((trans,index)=>
                            <tr> 
                              <th>{trans.raison_social}</th>
                              <th scope="row">{trans.email}</th>
                              <td className="text-center fw-bold text-dark">{trans.pourcent}</td>
                              <td className="text-center fw-bold text-danger">{separateNumber(trans.montant_initial)} </td>
                              <td className="text-center fw-bold text-success">{separateNumber(trans.montant_percent-trans.montant_reverse)} </td>
                              <td className="text-center fw-bold text-warning">{separateNumber(trans.montant_initial-trans.montant_percent)}</td>
                          </tr>
                          )
                        }
                      
                      </tbody>
                    </table>
  
                  </div>
  
                </div>
              </div>
           


            
         

          </div>
        </div>


                  {/* <div class="col-lg-4">
                  <div class="card">
                   
                    <div class="card-body pb-0">
                      <h5 class="card-title">Opérateur <span>| Semaine</span></h5>
                      {loading &&
                                <div class="spinner-grow" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              }
                     {!loading && 
                        <Chart
                            options={options1}
                            series={stateOperateurCompare.series.map((serie)=>parseInt(serie.total))}
                            type="donut" 
                            width="100%"
                            
                        />
                        }
        
                      
        
                    </div>
                  </div>
        
                </div>  */}
        



      </div>
    </section>
            </ContentPrincipal>
        </>
    )
}

export default HomeSoft;